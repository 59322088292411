import {plansData} from '../../data/plansData'
import React from 'react'
import './Plans.css'
import whiteTick from '../../assets/whiteTick.png'

const Plans = () => {
  return (
    <div className='plan-container' id='plans'>
        <div className="blur blur-p-l"></div>
        <div className="blur blur-p-r"></div>
        <div className="programe-header"  >
            <span className='stroke-text'>READY TO START</span>
            <span>YOUR JOURNEY</span>
            <span className='stroke-text'>NOW WITHUS</span>
        </div>

        <div className="plans">
            {plansData.map((plan, i) => (
                <div className="plan" key={i}>
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>$ {plan.price}</span>

                    <div className="features">
                        {plan.features.map((feature, i) => (
                            <div className="feature">
                                <img src={whiteTick} alt="" />

                                <span key={i}>
                                    {feature}
                                </span>
                            </div>
                        ))}
                    </div>
                    
                    <div>
                        <span>See More Benefits ->
                        </span>
                    </div>

                    <button className='btn'>
                        Join now
                    </button>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Plans