import React, { useState } from 'react'
import './Header.css'
import logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { Link } from 'react-scroll'

const Header = () => {

  const mobile = window.innerWidth <= 768 ? true : false
  const [menuOpend, setMenuopend] = useState(false)
    return (
    <div className="header">
        <img src={logo} className='logo' alt="" />

        {(menuOpend === false && mobile === true)? (
          <div
          style={{backgroundColor: 'var(--appColor)', padding: '0.5rem', borderRadius: '5px', cursor: 'pointer'}}
          onClick={()=> setMenuopend(true)}
          ><img src={Bars} alt="" style={{width: '1.5rem', height: '1.5rem'}} /></div>
        ): (
          <ul className='header-menu'>
            <li><Link
            onClick={()=> setMenuopend(false)}
            to='home'
            span={true}
            smooth={true}
            >Home</Link></li>
            <li onClick={()=> setMenuopend(false)}><Link
            onClick={()=> setMenuopend(false)}
            to='programs'
            span={true}
            smooth={true}
            >Programs</Link></li>
            <li onClick={()=> setMenuopend(false)}><Link
            onClick={()=> setMenuopend(false)}
            to='why'
            span={true}
            smooth={true}
            >Why Us</Link></li>
            <li onClick={()=> setMenuopend(false)}><Link
            onClick={()=> setMenuopend(false)}
            to='plans'
            span={true}
            smooth={true}
            >Plans</Link></li>
            <li onClick={()=> setMenuopend(false)}><Link
            onClick={()=> setMenuopend(false)}
            to='testimonials'
            span={true}
            smooth={true}
            >Testimonials</Link></li>
        </ul>
        )}
    </div>
  )
}

export default Header